var phoneWidth = parseInt(window.screen.width);
var phoneHeight = parseInt(window.screen.height);
var baseWidth = 1440; // 基准宽度
var phoneScale = phoneWidth / baseWidth; // 计算缩放比例
var ua = navigator.userAgent;
if (/Android (\d+\.\d+)/.test(ua)) {
    var version = parseFloat(RegExp.$1);
    if (version > 2.3) {
        document.write('<meta name="viewport" content="width=' + baseWidth + ', initial-scale=' + phoneScale + ', minimum-scale=' + phoneScale + ', maximum-scale=' + phoneScale + ', user-scalable=yes">');
    } else {
        document.write('<meta name="viewport" content="width=device-width, user-scalable=yes">');
    }
} else {
    document.write('<meta name="viewport" content="width=' + baseWidth + ', initial-scale=' + phoneScale + ', minimum-scale=' + phoneScale + ', maximum-scale=' + phoneScale + ', user-scalable=yes">');
}

//跳转登录
function login() {
    window.location.href = "https://clouspay.vip/#/login";
}

//跳转注册
function register() {
    window.location.href = "https://clouspay.vip/#/register";
}

//跳转客服
function onService() {
    window.open("https://clouspay.zonserp.com/chat/index?noCanClose=1&token=edbfb65d2d00ee79dbfa618644d98960");
}

//判断是不是微信端
if (navigator.userAgent.toLowerCase().indexOf('micromessenger') > -1) {
    $('.home-banner-photo-box').show();
    $('.home-banner').addClass('home-banner-wx');
    $('.cloudvideo').hide();
} else {
    $('.home-banner-photo-box').hide();
    $('.cloudvideo').show();
}